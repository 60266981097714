<template>
    <div id="add-admin" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">New Admin</h4>
                    <button ref="add_admin_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="col-md-12 mb-20">
                            <div class="form-group">
                                <input v-model="firstName" type="text" class="form-control" placeholder="First Name">
                                <p class="error" v-if="firstNameError">{{firstNameErrorMessage}}</p>
                            </div>
                            <div class="form-group">
                                <input v-model="lastName" type="text" class="form-control" placeholder="Last Name">
                                <p class="error" v-if="lastNameError">{{lastNameErrorMessage}}</p>
                            </div>
                            <div class="form-group">
                                <input v-model="email" type="text" class="form-control" placeholder="Email">
                                <p class="error" v-if="emailError">{{emailErrorMessage}}</p>
                            </div>
                            <div class="form-group">
                                <input v-model="phone" type="text" class="form-control" placeholder="Phone">
                                <p class="error" v-if="phoneError">{{phoneErrorMessage}}</p>
                            </div>
                            <div class="form-group">
                                <select v-model="roleId" class="form-control">
                                    <option value="">Select Role</option>
                                    <option v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}</option>
                                </select>
                                <p class="error" v-if="roleError">{{roleErrorMessage}}</p>
                            </div>
                            <!-- <div v-if="nomenclatureList && nomenclatureList.length>0" class="">
                              <span v-if="!nomenclatureList || nomenclatureList.length==0" class="spinner-border text-green"></span>
                              <select v-model="selectedNomenclature" class="form-control">
                                <option value="">Select Zone</option>
                                <option v-for="nomenclature in nomenclatureList" :key="nomenclature.id" :value="nomenclature">{{nomenclature.name}}</option>
                              </select>
                            </div> -->
                            <div v-if="showAssignNomenclature" class="">
                              <div class="form-group">
                                  <label>Rider Type</label>
                                  <div class="demo-checkbox">
                                        <input type="checkbox" id="pickup_ridertype" class="chk-col-red" value="pickup" v-model="riderType"/>
                                        <label for="pickup_ridertype">Pickup</label>
                                        <input type="checkbox" id="delivery_ridertype" class="chk-col-red" value="delivery" v-model="riderType"/>
                                        <label for="delivery_ridertype">Delivery</label>
                                  </div>
                              </div>
                              <div class="form-group">
                                <label>Assign Region</label>
                                  <select v-model="destinationType" @change="onDestinationTypeChange" class="form-control">
                                      <option value="">Select Destination Type</option>
                                      <option value="within-city">Within City</option>
                                      <option value="within-state">Within State</option>                                
                                      <option value="within-country">Within Country</option> 
                                  </select>
                                  <p class="error" v-if="destinationTypeError">{{destinationTypeErrorMessage}}</p>
                              </div>
                              <!-- <div v-if="destinationCountryList.length" class="form-group">
                                  <label>Select country</label>
                                  <select v-model="selectedDestinationCountryID" @change="onDestinationCountryChange" class="form-control">
                                      <option value="">Select Country</option>
                                      <option v-for="destinationCountry in destinationCountryList" :key="destinationCountry.id" :value="destinationCountry.id">{{destinationCountry.name}}</option>
                                  </select>
                                  <p class="error" v-if="destinationCountryError">{{destinationCountryErrorMessage}}</p>
                              </div>
                              <div v-if="destinationStateList.length" class="form-group">
                                  <label>Select State</label>
                                  <select v-model="selectedDestinationStateID" @change="onDestinationStateChange" class="form-control">
                                      <option value="">Select State</option>
                                      <option v-for="destinationState in destinationStateList" :key="destinationState.id" :value="destinationState.id">{{destinationState.name}}</option>
                                  </select>
                                  <p class="error" v-if="destinationStateError">{{destinationStateErrorMessage}}</p>
                              </div> -->
                              <div v-if="boundPlaceList.length" class="form-group">
                                  <label>Select Region</label>
                                  <p class="error" v-if="selectedBoundPlaceError">{{selectedBoundPlaceErrorMessage}}</p>
                                  <div class="demo-checkbox">
                                      <div v-for="boundPlace in boundPlaceList" :key="boundPlace.id">
                                          <input type="checkbox" :id="boundPlace.id" class="chk-col-red" :value="boundPlace.id" v-model="selectedBoundedPlaces"/>
                                          <label :for="boundPlace.id">{{boundPlace.name}}</label>
                                      </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveNewAdmin">Save<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
                <div class="col-md-12 mb-20">
                    <p class="text-danger" v-if="error">{{errorMessage}}</p>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../presenter'
import { mapGetters } from 'vuex'
export default {
  name: 'NewAdmin',
  components: {

  },
  computed: {
    ...mapGetters('region', {
        boundPlaceList: 'retrieveNomenclatureList',
    })
  },
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
    document.head.appendChild(sweetAlert)
  },
  props: {
    roles: Array
  },
  watch: {
    roles: function (data) {
      console.log('in new admin component watcher')
      console.log(data)
    },
    roleId: function(val) {
      for(let role of this.roles) {
        if (role.id==val && role.name.toLowerCase()=='rider') {
          this.showAssignNomenclature=true;
          break;
        }
        this.showAssignNomenclature=false;
      }
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,

      destinationType: '',
      destinationTypeError: false,
      destinationTypeErrorMessage: '',
      destinationCountryList: [],
      destinationStateList: [],
      selectedBoundedPlaces: [],
      selectedBoundPlaceError: false,
      selectedBoundPlaceErrorMessage: '',

      showAssignNomenclature: false,
      riderType: [],


      email: '',
      emailError: '',
      emailErrorMessage: '',
      firstName: '',
      firstNameError: '',
      firstNameErrorMessage: '',
      lastName: '',
      lastNameError: '',
      lastNameErrorMessage: '',
      phone: '',
      phoneError: '',
      phoneErrorMessage: '',
      roleId: '',
      roleError: '',
      roleErrorMessage: '',
      generalError: '',
      generalErrorMessage: '',
      selectedNomenclature: '',
      nomenclatureLoading: false,
      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    async loadNomenclatures(bound) {
      repository.regions.loadNomenclatureList(bound);
    },


    onDestinationTypeChange(event) {
        this.resetDestinationData();
        this.loadNomenclatures(event.target.value); // within-country, within-state, within-city
    },

    resetDestinationData(){
        this.selectedBoundedPlaces = [];
    },




    async saveNewAdmin () {
      if (this.loading) return
      this.resetData()
      const newAdminDataValidation = this.validateNewAdminData()
      if (!newAdminDataValidation.success) return
      this.loading = true
      const data = {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        phone: this.phone,
        role_id: this.roleId,
        nomenclatureID: this.selectedBoundedPlaces,
        riderType: this.riderType,
      }
      console.log('data is ', data)
      const response = await repository.user.createNewAdmin(data)
      this.loading = false
      console.log('response is ', response)
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Admin Created');
        this.clearForm();
        return
      }
      this.showErrorMessage(response.data)
    },

    validateNewAdminData () {
      const returnValue = new Object()
      if (!this.email) {
        this.emailError = true
        this.emailErrorMessage = 'Enter a valid email'
        returnValue.success = false
        return returnValue
      }
      if (!this.firstName) {
        this.firstNameError = true
        this.firstNameErrorMessage = 'Enter a valid first name'
        returnValue.success = false
        return returnValue
      }
      if (!this.lastName) {
        this.lastNameError = true
        this.lastNameErrorMessage = 'Enter a valid last name'
        returnValue.success = false
        return returnValue
      }
      if (!this.phone) {
        this.phoneError = true
        this.phoneErrorMessage = 'Enter a valid phone number'
        returnValue.success = false
        return returnValue
      }
      if (!this.roleId) {
        this.roleError = true
        this.roleErrorMessage = 'Select a valid role'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.add_admin_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.generalError = false
      this.emailError = false
      this.firstNameError = false
      this.lastNameError = false
      this.roleError = false
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    },
    clearForm() {
      this.firstName="";
      this.lastName="";
      this.email="";
      this.phone="";
      this.roleId="";
      this.showAssignNomenclature=false;
      this.destinationType="";
      this.selectedBoundedPlaces=[];
      this.riderType = [];
    },
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

