<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
            <h1>
                FTD Admins
            </h1>
            <ol class="breadcrumb">
                <!-- <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><a href="#">Examples</a></li>
                <li class="breadcrumb-item active">Contact</li> -->
            </ol>
        </section>

        <!-- Main content -->
        <section class="content">

            <div v-if="loading" class="spinner-border spinner text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>

            <div class="row">
                <div class="col-12">
                    <form novalidate>
                        <div class="box bg-pale-secondary box-outline-dark">
                            <div class="box-body">
                                <div class="contact-page-aside">
                                    <ul class="list-style-none list-inline font-size-16">
                                        <li>
                                            <div class="form-group">
                                                <div class="controls">
                                                    <input v-model="email" type="text" name="text" class="form-control" placeholder="Email">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-group">
                                                <div class="controls">
                                                    <input v-model="name" type="text" name="text" class="form-control" placeholder="Name">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-group">
                                                <div class="controls">
                                                    <select v-model="role_id" name="select" id="select" required class="form-control">
                                                        <option value="">All Roles</option>
                                                        <option v-for="adminRole in adminRoles" :key="adminRole.id" :value="adminRole.id">{{adminRole.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="box-label float-right"><a href="javascript:void(0)" class="btn btn-primary text-white mt-10" @click="loadSearchResult()">Search</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- /. box -->
                    </form>
                </div>
                <!-- /.col -->

                <div class="col-12">
                <div class="box box-solid bg-primary">
                    <div class="box-header with-border">
                        <h4 class="box-title">Admins</h4>
                        <div class=" float-right">
                            <button @click="requestExport" class="btn btn-sm btn-success">Export Excel<span v-if="exportLoading" class="spinner-border text-white"></span></button><br>
                        </div>
                        <div class=" float-right">
                            <a  v-if="downloadLink" :href="downloadLink" target="_blank" class="btn btn-sm btn-success">Download Excel</a>
                        </div>
                    </div>
                    <div class="box-body">
                    <div class="table-responsive">
                        <table class="table table-hover no-wrap">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Role</th>
                                    <th>Registration Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(admin, index) in adminList" :key="admin.id">
                                    <td>{{ (index+1) + (Number(metaData.per_page) * (Number(metaData.current_page)-1)) }}</td>
                                    <td>{{ admin.first_name }} {{ admin.last_name }}</td>
                                    <td>{{ admin.email }}</td>
                                    <td>{{ admin.phone ? admin.phone : 'not available' }}</td>
                                    <td>
                                        {{ admin.role ? admin.role.name : 'Admin' }}<br/>
                                        
                                    </td>
                                    <td>{{ admin.created_at_formatted }}</td>
                                    
                                    <td>
                                        <router-link :to="{path: `/admin/${admin.id}`}" class="btn btn-success btn-sm"><i class="ti-eye" aria-hidden="true">View</i></router-link>
                                    </td>
                                </tr>
                                
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="3">
                                        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#add-admin">Add New Admin</button>
                                    </td>
                                    <td colspan="4">
                                        <div class="text-right">
                                            <ul v-if="metaData" class="pagination pagination-sm">
                                                <li v-for="index in metaData.last_page" :class="{active: index==Number(metaData.current_page)}" :key="index"><a href="#" @click="fetchCustomer(index)">{{ index }}</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <NewAdmin :roles="adminRoles"/>
                    </div>
                    </div>
                    <!-- /.box-body -->
                </div>
                <!-- /. box -->























                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

        </section>
        <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
</template>

<script>
import NewAdmin from '../../components/NewAdmin'
import { repository } from '../../presenter'
import { mapGetters } from 'vuex'
export default {
  components: {
    NewAdmin
  },
  data () {
    return {
        email: '',
        name: '',
        role_id: '',
        loading: false,
        exportLoading: false,
        downloadLink: '',

        adminList: null,
        metaData: null,
    }
  },
  computed: {
    ...mapGetters('role', {
      adminRoles: 'retrieveAdminRoles',
    }),
  },
  mounted () {
    const datatable = document.createElement('script')
    datatable.setAttribute('src', `/assets/styles/vendor_components/datatable/datatables.min.js`)
    document.head.appendChild(datatable)
    datatable.onload = function(){
        const dataTable2JS = document.createElement('script')
        dataTable2JS.setAttribute('src', '/assets/js/pages/data-table.js')
        document.head.appendChild(dataTable2JS)
    }


    this.loadAdminRoles()
    this.loadSearchResult()
  },
  methods: {
    loadAdminRoles () {
      repository.role.loadAdminRolesToStore()
    },
    async loadSearchResult (page=1) {
        const data = {
            email: this.email,
            name: this.name,
            role_id: this.role_id
        }

        this.loading=true;
        const response = await repository.user.search(data, page);
        this.loading=false;
        if (response.success) {
            this.adminList = response.data;
            this.metaData = response.extra_data;
        }
    },
    async requestExport(){
        const data = {
            email: this.email,
            name: this.name,
            role_id: this.role_id
        }

        this.exportLoading = true;
        this.downloadLink='';
        const response = await repository.user.requestExport(data);
        this.exportLoading = false;
        if (response.success) {
            this.downloadLink = response.data.path
        }
    },
  }
}
</script>

<style scoped>
.spinner {
  display: block;
  position: fixed;
  width: 3rem; 
  height: 3rem;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: calc( 50% - ( 3rem / 2) ); /* where ... is the element's height */
  right: calc( 50% - ( 3rem / 2) ); /* where ... is the element's width */
}
.active {
    color: #fff;
    border: 1px solid #145388;
    background-color: #145388;
}
</style>
